<template>
    <DataTable
        title="Projects"
        :headers="Project.headers()"
        :model-class="Project"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create Project',
            updateDialogBtnText: 'Update Project',
            deleteDialogBtnText: 'Delete Project',
        }"
        :config="useConfig().project"
    >
        <template #create-action-dialog="{ item }">
            <CreateProjectDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdateProjectDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteProjectDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { Project } from '@app/land-hold/project/models/Project';
import { useConfig } from '@shared/composables/useConfig';

const page = Project.routes().index.page;
</script>
