import { Model } from '@shared/models/Model';
import { type IndexApprovalAuthorityIncludes, routes } from '@app/land-hold/approval-authority/routes/routes';
import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';

export interface IApprovalAuthority extends IModel {
    name: string;
}

export class ApprovalAuthority extends Model implements IApprovalAuthority {
    public name!: string;

    public constructor(o: IApprovalAuthority) {
        super(o);
        Object.assign(this, o);
    }

    public hidden(): (keyof IApprovalAuthority)[] {
        return [];
    }

    public static override headers(): DataTableHeaders<IndexApprovalAuthorityIncludes> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 113, minWidth: '113', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Name', key: 'name' },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}
