import type { IndexRoute, ShowRoute } from '@shared/types/Route';
import { type IPublicTransaction, PublicTransaction } from '@app/purchase/transaction/models/PublicTransaction';
import type { Include, SingleObjectInclude } from '@shared/types/ApiResponse.ts';
import type { ICertifier } from '@app/land-hold/certifier/models/Certifier.ts';

export const AvailableIncludes = {
    Blocks: 'blocks',
    Certifier: 'certifier',
} as const;

export type CertifierInclude = Include<typeof AvailableIncludes, 'certifier', ICertifier, SingleObjectInclude<ICertifier> | null>;

export type AllTransactionIncludes = IPublicTransaction & CertifierInclude;
export type IndexPublicTransactionIncludes = IPublicTransaction & CertifierInclude;
export type ShowTransactionIncludes = IndexPublicTransactionIncludes;

export function routes(): {
    index: IndexRoute<'public.transactions.index', IndexPublicTransactionIncludes>;
    show: ShowRoute<'public.transactions.show', ShowTransactionIncludes>;
} {
    return {
        index: {
            method: 'get',
            name: 'public.transactions.index',
            datatableHeaders: () => PublicTransaction.headers(),
            params: { include: [AvailableIncludes.Blocks, AvailableIncludes.Certifier] },
            page: usePage(),
        },
        show: {
            method: 'get',
            name: 'public.transactions.show',
            params: { include: [AvailableIncludes.Blocks, AvailableIncludes.Certifier] },
            page: usePage(),
        },
    };
}
