<template>
    <DataTable
        title="Approval Authorities"
        :headers="ApprovalAuthority.headers()"
        :model-class="ApprovalAuthority"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create',
            updateDialogBtnText: 'Update',
            deleteDialogBtnText: 'Delete',
            createDialogTitle: 'Create Approval Authority',
            updateDialogTitle: 'Update Approval Authorities',
            deleteDialogTitle: 'Delete Approval Authorities',
        }"
        :config="useConfig().approvalAuthority"
    >
        <template #create-action-dialog="{ item }">
            <CreateApprovalAuthorityDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdateApprovalAuthorityDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteApprovalAuthorityDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { useConfig } from '@shared/composables/useConfig';
import { type IApprovalAuthority, ApprovalAuthority } from '@app/land-hold/approval-authority/models/ApprovalAuthority';
import type { PaginatedResponse } from '@shared/types/ApiResponse';

const page = usePage<PaginatedResponse<IApprovalAuthority>>();
</script>
