<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
            <v-col cols="12" sm="6">
                <v-row justify="space-around">
                    <v-col cols="auto">
                        <v-switch v-model="form.public" inset :color="Certifier.getPublicFieldStateClassColor(form.public)" :label="Certifier.getPublicFieldLabel(form.public)" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="12">
                <ValidatableTextField v-model:form="form" placeholder="https://example.com" label="Embedded Map" :field="'embed_url'" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { Certifier, type ICertifier } from '@app/land-hold/certifier/models/Certifier';
import { useCertifierRepository } from '@app/land-hold/certifier/repositories/useCertifierRepository';

const props = defineProps({
    item: {
        type: Object as PropType<ICertifier>,
        required: true,
    },
});

const update = useCertifierRepository().command.update(props.item.id, props.item);
const form = update.form;
useInjectSubmitForm(update.execute);
</script>
