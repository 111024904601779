import { Model } from '@shared/models/Model';
import { type AllProjectIncludes, type IndexProjectIncludes, routes } from '@app/land-hold/project/routes/routes';
import type { ChipConfig, DataTableHeaders, DotNotationOf } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';
import type { ValuesOfConst } from '@shared/types/ApiResponse.ts';

const ApprovalStatus = {
    Approved: 'approved',
    Pending: 'pending',
    Rejected: 'rejected',
    InReview: 'in_review',
} as const;

export interface IProject extends IModel {
    name: string;
    steward_id: string;
    approval_authority_id: string;
    approval_status: ValuesOfConst<typeof ApprovalStatus>;
    sent_for_approval_at: string;
    start_date: string;
    end_date: string|null;
    property_count: number;
}

export class Project extends Model implements IProject {
    name!: string;
    steward_id!: string;
    approval_authority_id!: string;
    approval_status!: ValuesOfConst<typeof ApprovalStatus>;
    sent_for_approval_at!: string;
    start_date!: string;
    end_date!: string|null;
    property_count!: number;

    public constructor(o: IProject) {
        super(o);
        Object.assign(this, o);
    }

    public static override chips(): DotNotationOf<AllProjectIncludes>[] {
        return ['approval_status'];
    }

    public static override chipConfig(field: DotNotationOf<AllProjectIncludes>, item: AllProjectIncludes): ChipConfig {
        let color;
        switch (field) {
            case 'approval_status':
                color = Project.getStatusColor(item.approval_status);
                return { size: 'small', color, text: item.approval_status };
            default:
                return { size: 'small' };
        }
    }

    public static getStatusColor(status: ValuesOfConst<typeof ApprovalStatus>): string {
        switch (status) {
            case ApprovalStatus.Rejected:
                return 'error';
            case ApprovalStatus.Approved:
                return 'success';
            case ApprovalStatus.InReview:
                return 'warning';
            default:
                return '';
        }
    }

    public static isPendingApproval(item: IProject): boolean {
        return item.approval_status === ApprovalStatus.Pending;
    }

    public static approvalStatuses(): string[] {
        return Object.values(ApprovalStatus);
    }

    public static override headers(): DataTableHeaders<IndexProjectIncludes> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 113, minWidth: '113', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Name', key: 'name' },
            { title: 'Steward', key: 'steward.data.name', sortable: false },
            { title: 'Approval Authority', key: 'approvalAuthority.data.name', sortable: false },
            { title: 'Linked Properties', key: 'property_count', align: 'center', sortable: false },
            { title: 'Status', key: 'approval_status', sortable: false },
            { title: 'Sent Date', key: 'sent_for_approval_at', date: true },
            { title: 'Start Date', key: 'start_date', date: true },
            { title: 'End Date', key: 'end_date', date: true },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}
