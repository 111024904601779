import { Model } from '@shared/models/Model';
import { type AllTransactionIncludes, type IndexTransactionIncludes, routes } from '@app/purchase/transaction/routes/routes';
import type { CsvStructure } from '@shared/composables/useDownloadCsv';
import { useStewardRepository } from '@app/land-hold/steward/repositories/useStewardRepository';
import { useClientRepository } from '@app/purchase/client/repositories/useClientRepository';
import { useTransactionRepository } from '@app/purchase/transaction/repositories/useTransactionRepository';
import type { DotNotationOf, ChipConfig, DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';
import type { CrudOperation, Operation } from '@shared/types/ApiOperation';

enum TransactionStatus {
    Fulfilled = 'Fulfilled',
    NotFulfilled = 'Not Fulfilled',
}

interface FulfillTransactionOperation extends Operation {
    object: 'TransactionOperation';
    name: 'fulfill';
    allowed: boolean;
}

type TransactionOperation = CrudOperation & FulfillTransactionOperation;
interface TransactionOperations {
    data: TransactionOperation[];
}

export interface ITransaction extends IModel {
    airtable_id: string;
    carbon_amount_tco2e: string;
    date_of_transaction: string;
    company_id: string;
    company_name: string;
    airtable_company_id: string;
    transaction_type_id: string;
    steward_id: string;
    steward_code: string;
    partner_id: string | null;
    certifier_id: string | null;
    airtable_steward_id: string;
    transaction_short_code: string;
    transaction_code: string;
    sum_carbon_in_blocks: string;
    uncovered_carbon_amount: string;
    status: TransactionStatus;
    // TODO: this have to be moved and used like Includes
    //  because it is only returned in some cases (index endpoint)
    operations?: TransactionOperations;
}

export class Transaction extends Model implements ITransaction {
    public airtable_id!: string;
    public carbon_amount_tco2e!: string;
    public date_of_transaction!: string;
    public company_id!: string;
    public company_name!: string;
    public airtable_company_id!: string;
    public transaction_type_id!: string;
    public steward_id!: string;
    public steward_code!: string;
    public partner_id!: string | null;
    public certifier_id!: string | null;
    public airtable_steward_id!: string;
    public transaction_short_code!: string;
    public transaction_code!: string;
    public sum_carbon_in_blocks!: string;
    public uncovered_carbon_amount!: string;
    public status!: TransactionStatus;
    public declare operations: TransactionOperations;

    public constructor(o: ITransaction) {
        super(o);
        Object.assign(this, o);
    }

    public hidden(): (keyof AllTransactionIncludes)[] {
        return ['airtable_id', 'company_name', 'airtable_company_id', 'steward_code', 'airtable_steward_id', 'transaction_short_code', 'transaction_code', 'transaction_type', 'sum_carbon_in_blocks', 'uncovered_carbon_amount', 'status'];
    }

    public static override chips(): DotNotationOf<AllTransactionIncludes>[] {
        return ['status'];
    }

    public static override chipConfig(field: DotNotationOf<AllTransactionIncludes>, item: AllTransactionIncludes): ChipConfig {
        switch (field) {
            case 'status':
                return { size: 'small', color: Transaction.getStatusColor(item.status) };
            default:
                return { size: 'small' };
        }
    }

    public static getStatusColor(status: TransactionStatus): string {
        switch (status) {
            case TransactionStatus.NotFulfilled:
                return 'error';
            case TransactionStatus.Fulfilled:
                return 'success';
            default:
                return 'info';
        }
    }

    public override can(): ReturnType<Model['can']> & { fulfill: boolean } {
        const fulfill: FulfillTransactionOperation | undefined = this.operations.data.find((operation: Operation) => operation.name === 'fulfill');
        return {
            ...super.can(),
            fulfill: fulfill?.allowed ?? true,
        };
    }

    public static override headers(): DataTableHeaders<IndexTransactionIncludes> {
        return [
            {
                title: 'Actions',
                key: 'actions',
                sortable: false,
                fixed: true,
                width: 113,
                minWidth: '113',
                headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } },
                cellProps() {
                    return { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } };
                },
            },
            { title: 'Transaction Code', key: 'transaction_code', link: (item: IndexTransactionIncludes) => useTransactionRepository().query.show(item.id).endpoint, fixed: true, width: 226.23 },
            { title: 'Carbon (tCO2e)', key: 'carbon_amount_tco2e', align: 'center' },
            { title: 'Date', key: 'date_of_transaction', date: true },
            { title: 'Client', key: 'company_name', link: (item: IndexTransactionIncludes) => useClientRepository().query.show(item.company_id).endpoint },
            { title: 'Certificate Owner', key: 'certifier.data.name' },
            { title: 'Steward', key: 'steward_code', link: (item: IndexTransactionIncludes) => useStewardRepository().query.show(item.steward_id).endpoint },
            { title: 'Partner', key: 'partner.data.name', sortable: false },
            { title: 'Short Code', key: 'transaction_short_code' },
            { title: 'Transaction Type', key: 'transaction_type.data.name' },
            { title: 'Sum Carbon Allocated (tCO2e)', key: 'sum_carbon_in_blocks', sortable: false, align: 'center' },
            { title: 'Open Amount Carbon (tCO2e)', key: 'uncovered_carbon_amount', sortable: false, align: 'center' },
            { title: 'Status', key: 'status', sortable: false },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static csvHeaders(): CsvStructure[] {
        return Transaction.headers()
            .filter((header) => header.key !== 'actions')
            .map((header) => ({ title: header.title, key: header.key })) as CsvStructure[];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}
