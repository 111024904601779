<template>
    <v-dialog v-model="dialogOpen" width="290px">
        <template #activator="{ props: activatorProps }">
            <v-text-field v-model="field" :label="label" readonly v-bind="activatorProps" :error-messages="transformError(form.errors[fieldName])" :clearable="clearable" :disabled="disabled" @click:clear="clear" />
        </template>
        <v-date-picker v-model="internalDate" color="primary" />
    </v-dialog>
</template>

<script setup lang="ts" generic="T extends InertiaFormData">
import type { DatePickerDate } from '@shared/types/DatePickerDate';
import { useDate } from '@shared/composables/useDate';
import { useUtility } from '@shared/composables/useUtility';
import type { InertiaFormData, PrecognitionForm } from '@shared/types/Form';

const { transformError, isString } = useUtility();
const dateUtil = useDate();

const props = defineProps({
    // The name of the field in the form. This is used as the key to get the validation errors from the form
    fieldName: {
        // TODO: can be improved. It currently also shows the methods of the form
        type: Object as PropType<keyof T>,
        required: true,
    },
    label: {
        type: String,
        default: 'Date',
    },
    clearable: {
        type: Boolean,
        default: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const form = defineModel<PrecognitionForm<T>>('form', {
    type: Object as PropType<PrecognitionForm<T>>,
    required: true,
});

const field = defineModel<DatePickerDate>('field', {
    type: String,
    required: true,
    get(v) {
        if (isString(v)) {
            return dateUtil.toISO8601(v);
        }
        return null;
    },
    set(v) {
        return dateUtil.toISO8601(v);
    },
});

watch(field, () => {
    if (useUtility().isString(props.fieldName)) {
        form.value.validate(props.fieldName);
    }
});

const dialogOpen = ref(false);
const internalDate = ref<DatePickerDate>(null);
const initialRun = ref(true);
watch(
    internalDate,
    (newValue) => {
        if (initialRun.value) {
            initialize();
        } else {
            dialogOpen.value = false;
            field.value = newValue;
        }
    },
    {
        immediate: true,
    },
);

function initialize() {
    initialRun.value = false;
    internalDate.value = field.value;
}

function clear() {
    internalDate.value = null;
}
</script>
