<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="[{ title: 'Transactions', to: useRoute().build(PublicTransaction.routes().index) }, { title: 'Transaction Details' }]" />
        </template>
        <template #content>
            <v-card class="ma-3">
                <v-tabs v-model="tab">
                    <v-tab value="one" rounded="0">Transaction</v-tab>
                    <v-tab value="two" rounded="0">Blocks</v-tab>
                </v-tabs>
                <v-card-text>
                    <v-tabs-window v-model="tab">
                        <v-tabs-window-item value="one">
                            <v-row>
                                <v-col>
                                    <v-card-title>Transaction Details</v-card-title>
                                </v-col>
                                <v-col cols="auto">
                                    <v-img v-show="displayUberLogo" src="@shared/assets/images/uber-logo.png" max-width="70" min-width="70" />
                                </v-col>
                            </v-row>
                            <v-row align="start">
                                <v-col cols="12" md="6">
                                    <v-row>
                                        <v-col>
                                            <ReadOnlyTextField :value="page.props.data.transaction_code" label="Transaction Code" />
                                        </v-col>
                                        <v-col>
                                            <ReadOnlyTextField v-show="page.props.data.certifier?.data.public" :value="page.props.data.certifier?.data.name" label="Certificate Owner" />
                                        </v-col>
                                        <v-responsive width="100%" />
                                        <v-col>
                                            <ReadOnlyDateTextField :date="page.props.data.execution_date" label="Date" />
                                        </v-col>
                                        <v-col>
                                            <ReadOnlyTextField :value="page.props.data.carbon_amount_tco2e" label="Carbon (tCO2e)" />
                                        </v-col>
                                        <v-responsive width="100%" />
                                        <v-col>
                                            <ReadOnlyTextField :value="page.props.data.steward_name" label="Steward" />
                                        </v-col>
                                        <v-col>
                                            <ReadOnlyTextField :value="page.props.data.type" label="Transaction Type Name" />
                                        </v-col>
                                        <v-responsive width="100%" />
                                        <v-col>
                                            <ReadOnlyTextField :value="page.props.data.country" label="Country" />
                                        </v-col>
                                        <v-col>
                                            <ReadOnlyTextField v-show="page.props.data.state" :value="page.props.data.state" label="State" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <iframe v-show="page.props.data.certifier?.data.public" class="mapContainer" :src="page.props.data.certifier?.data.embed_url ?? ''" :allowfullscreen="true" />
                                </v-col>
                            </v-row>
                        </v-tabs-window-item>

                        <v-tabs-window-item value="two">
                            <v-row>
                                <v-col>
                                    <v-card-title>Blocks</v-card-title>
                                </v-col>
                            </v-row>
                            <v-data-table :items="page.props.data.blocks.data" :headers="PublicBlock.headers()">
                                <template v-for="column in dateColumns" :key="column.key" #[`item.${column.key}`]="{ item }">
                                    {{ dateUtil.toISO8601(getValue(item, column.key)) }}
                                </template>
                            </v-data-table>
                        </v-tabs-window-item>
                    </v-tabs-window>
                </v-card-text>
            </v-card>
            <v-footer :color="theme.global.current.value.colors.background">
                <v-row>
                    <v-col>
                        <div>
                            <span style="font-size: 12px; font-weight: bold; color: dimgrey">Powered by </span>
                            <a href="https://openforests.com" style="font-size: 12px; font-weight: bold; color: dimgrey" target="_blank" rel="noopener noreferrer">OpenForests</a>
                            <br />
                            <span style="font-size: 12px; font-style: italic; color: grey">Empowering sustainable landscapes through digital innovation.</span>
                        </div>
                    </v-col>
                </v-row>
            </v-footer>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts">
import { useRoute } from '@shared/composables/useRoute';
import { PublicTransaction } from '@app/purchase/transaction/models/PublicTransaction';
import { PublicBlock } from '@app/purchase/block/models/PublicBlock';
import { useDate } from '@shared/composables/useDate';

const page = PublicTransaction.routes().show.page;

const tab = ref(null);
const dateColumns = computed(() => PublicBlock.headers().filter((column) => column.date));
const dateUtil = useDate();
const theme = useTheme();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getValue(item: any, key: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return key.split('.').reduce((o, k) => o[k], item);
}

const displayUberLogo = computed(() => page.props.data.certifier?.data.name.toLowerCase() === 'uber');
</script>

<style lang="scss" scoped>
.mapContainer {
    height: 400px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>
