<template>
    <p>Are you sure you want to delete this Approval Authority?</p>
    <br />
    <p>[ {{ item.name }} ]</p>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type IApprovalAuthority } from '@app/land-hold/approval-authority/models/ApprovalAuthority';
import { useApprovalAuthorityRepository } from '@app/land-hold/approval-authority/repositories/useApprovalAuthorityRepository';

const props = defineProps({
    item: {
        type: Object as PropType<IApprovalAuthority>,
        required: true,
    },
});

const destroy = useApprovalAuthorityRepository().command.destroy(props.item.id);
useInjectSubmitForm(destroy.execute);
</script>
