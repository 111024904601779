import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import { type IApprovalAuthority, ApprovalAuthority } from '@app/land-hold/approval-authority/models/ApprovalAuthority';

export type AllApprovalAuthorityIncludes = IApprovalAuthority;
export type IndexApprovalAuthorityIncludes = AllApprovalAuthorityIncludes;
export function routes(): {
    index: IndexRoute<'approval-authorities.index', IndexApprovalAuthorityIncludes>;
    show: ShowRoute<'approval-authorities.show'>;
    destroy: DestroyRoute<'approval-authorities.destroy'>;
    create: CreateRoute<'approval-authorities.create'>;
    store: StoreRoute<'approval-authorities.store'>;
    edit: EditRoute<'approval-authorities.edit'>;
    update: UpdateRoute<'approval-authorities.update'>;
} {
    return {
        index: {
            method: 'get',
            name: 'approval-authorities.index',
            params: {
                orderBy: 'name',
                sortedBy: 'desc',
            },
            datatableHeaders: () => ApprovalAuthority.headers(),
            page: usePage(),
        },
        show: { method: 'get', name: 'approval-authorities.show', page: usePage() },
        destroy: { method: 'delete', name: 'approval-authorities.destroy', page: usePage() },
        create: { method: 'get', name: 'approval-authorities.create', page: usePage() },
        store: { method: 'post', name: 'approval-authorities.store', page: usePage() },
        edit: { method: 'get', name: 'approval-authorities.edit', page: usePage() },
        update: { method: 'patch', name: 'approval-authorities.update', page: usePage() },
    };
}
