<template>
    <v-form>
        <v-row>
            <v-col cols="6">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type IApprovalAuthority } from '@app/land-hold/approval-authority/models/ApprovalAuthority';
import { useApprovalAuthorityRepository } from '@app/land-hold/approval-authority/repositories/useApprovalAuthorityRepository';

const props = defineProps({
    item: {
        type: Object as PropType<IApprovalAuthority>,
        required: true,
    },
});

const store = useApprovalAuthorityRepository().command.store(props.item);
const form = store.form;
useInjectSubmitForm(store.execute);
</script>
