<template>
    <p>Are you sure you want to delete this Certificate Owner?</p>
    <br />
    <p>[ {{ item.name }} ]</p>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type ICertifier } from '@app/land-hold/certifier/models/Certifier';
import { useCertifierRepository } from '@app/land-hold/certifier/repositories/useCertifierRepository';

const props = defineProps({
    item: {
        type: Object as PropType<ICertifier>,
        required: true,
    },
});

const destroy = useCertifierRepository().command.destroy(props.item.id);
useInjectSubmitForm(destroy.execute);
</script>
