<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="[{ title: 'Transactions', to: useRoute().build(Transaction.routes().index) }, { title: 'Transaction Details' }]" />
        </template>
        <template #content>
            <v-card class="ma-3">
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.transaction_code" label="Transaction Code" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.carbon_amount_tco2e" label="Carbon (tCO2e)" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyDateTextField :date="page.props.data.date_of_transaction" label="Date" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.company_name" label="Client" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.certifier?.data.name ?? page.props.data.company_name" label="Certificate Owner" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.steward_code" label="Steward" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.transaction_short_code" label="Short Code" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.transaction_type.data.name" label="Transaction Type Name" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.sum_carbon_in_blocks" label="Sum Carbon Allocated (tCO2e)" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.uncovered_carbon_amount" label="Open Amount Carbon (tCO2e)" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn :to="useRoute().build(Transaction.routes().blocksIndex, { id: page.props.data.id })" color="primary">View Blocks</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts">
import { Transaction } from '@app/purchase/transaction/models/Transaction';
import { useRoute } from '@shared/composables/useRoute';

const page = Transaction.routes().show.page;
</script>
