import { Model } from '@shared/models/Model';
import { type IndexPublicTransactionIncludes, routes } from '@app/purchase/transaction/routes/routes.public';
import { useTransactionRepository } from '@app/purchase/transaction/repositories/useTransactionRepository';
import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';
import type { MultiObjectInclude } from '@shared/types/ApiResponse';
import type { IBlock } from '@app/purchase/block/models/Block';

export interface IPublicTransaction extends IModel {
    transaction_code: string;
    carbon_amount_tco2e: string;
    execution_date: string;
    type: string;
    steward_name: string;
    partner_name: string | null;
    sum_carbon_in_blocks: string;
    uncovered_carbon_amount: string;
    country: string | null;
    state: string | null;
    blocks: MultiObjectInclude<IBlock>;
}

export class PublicTransaction extends Model implements IPublicTransaction {
    public transaction_code!: string;
    public carbon_amount_tco2e!: string;
    public execution_date!: string;
    public type!: string;
    public steward_name!: string;
    public partner_name!: string | null;
    public sum_carbon_in_blocks!: string;
    public uncovered_carbon_amount!: string;
    public country!: string | null;
    public state!: string | null;
    public blocks!: MultiObjectInclude<IBlock>;

    public constructor(o: IPublicTransaction) {
        super(o);
        Object.assign(this, o);
    }

    public static override headers(): DataTableHeaders<IndexPublicTransactionIncludes> {
        return [
            {
                title: 'Transaction Code',
                key: 'transaction_code',
                link: (item) => useTransactionRepository().query.showPublic(item.id).endpoint,
                fixed: true,
                width: 226.23,
            },
            { title: 'Date', key: 'execution_date', date: true },
            { title: 'Carbon (tCO2e)', key: 'carbon_amount_tco2e', align: 'center' },
            { title: 'Steward', key: 'steward_name', sortable: false },
            { title: 'Transaction Type', key: 'type' },
        ];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}
