import type { GeneralConfig } from '@shared/types/Config';

export const transactionConfig: GeneralConfig = {
    sortFieldMap: {
        'transaction_type.data.name': 'transaction_types:transaction_type_id|name',
        company_name: 'clients:company_id,id|clients.name',
        'certifier.data.name': 'certifiers:certifier_id,id|certifiers.name',
        steward_code: 'stewards:steward_id,id|stewards.steward_code',
    },
};
