<template>
    <DataTable
        title="Certificate Owners"
        :headers="Certifier.headers()"
        :model-class="Certifier"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create Certificate Owner',
            updateDialogBtnText: 'Update Certificate Owner',
            deleteDialogBtnText: 'Delete Certificate Owner',
        }"
        :config="useConfig().certifier"
    >
        <template #create-action-dialog="{ item }">
            <CreateCertifierDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdateCertifierDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteCertifierDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { Certifier, type ICertifier } from '@app/land-hold/certifier/models/Certifier.ts';
import { useConfig } from '@shared/composables/useConfig';
import type { PaginatedResponse } from '@shared/types/ApiResponse';

const page = usePage<PaginatedResponse<ICertifier>>();
</script>
