<template>
    <v-text-field v-model="form[field as keyof T]" :placeholder="placeholder" :hint="hint" :type="type" :label="label" :error-messages="transformError(form.errors[field])" @change="form.validate(field as string)" />
</template>

<script setup lang="ts" generic="T extends InertiaFormData">
import { useUtility } from '@shared/composables/useUtility';
import type { InertiaFormData, PrecognitionForm } from '@shared/types/Form';

const { transformError } = useUtility();

defineProps({
    // TODO: change it to fieldName to match other validatable component inputs like DatePicker and Autocomplete components
    field: {
        // TODO: can be improved. It currently also shows the methods of the form
        type: Object as PropType<keyof T>,
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
    type: {
        type: String as PropType<'text' | 'number'>,
        default: 'text',
    },
    hint: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: null,
    },
});

const form = defineModel<PrecognitionForm<T>>('form', {
    required: true,
});
</script>
